<template>
  <div class="user-profile--meal-history">
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <meal-guide-history-list
          :lists="histories.list"
          :meta="histories.listMeta"
          :loading="loading"
          @loadMore="loadMore"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MealGuideHistoryList from './components/MealGuideHistoryList'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserMealGuideHistory',

  components: {
    MealGuideHistoryList,
  },

  data() {
    return {
      loading: false,
      histories: {
        list: [],
        listMeta: {
          current_page: 0,
          last_page: 1,
        },
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'users/currentSelectedUserProfile',
      program: 'users/currentSelectedProgram',
    }),

    hasHistories() {
      return !!this.histories.length
    },
  },

  created() {
    this.fetchUserMealHistory()
  },

  methods: {
    ...mapActions({
      getMealGuideHistories: 'users/getMealGuideHistories',
    }),

    async fetchUserMealHistory(page = 1) {
      if (!this.program.id || !this.user.id || this.loading) return

      this.loading = true

      let { data } = await this.getMealGuideHistories({
        userId: this.user.id,
        programId: this.program.user_program.id,
        page,
      })

      this.histories.list = data.data
      this.histories.listMeta = data.meta

      await this.$sleep()

      this.loading = false
    },

    loadMore() {
      if (
        this.histories.listMeta.current_page < this.histories.listMeta.last_page
      ) {
        this.fetchUserMealHistory(this.listMeta.current_page + 1)
      }
    },
  },

  watch: {
    program(program) {
      this.fetchUserMealHistory()
    },
  },
}
</script>
