<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Meal Guide</th>
          <th class="text-left">Date</th>
          <th class="text-left">Activity</th>
          <th class="text-left">User & Role</th>
        </tr>
      </thead>

      <tbody
        v-if="hasHistories"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          class="clickable"
          v-for="(history, index) in histories"
          :key="index"
        >
          <td>
            <v-list-item class="px-0" two-line>
              <v-list-item-content>
                <v-list-item-title v-text="history.meal_guide.slug" />
                <v-list-item-subtitle>
                  <span>
                    Template No. {{ history.meal_guide.template_number }},
                  </span>
                  <span>
                    Restriction: {{ history.meal_guide.restriction_code }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </td>
          <td>
            {{ history.created_at | formatDate('MMM DD, YYYY HH:mm a') }}
          </td>
          <td>{{ history.type | mealGuideType }}</td>
          <td>
            <v-list-item class="px-0" three-line>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="!history.assigned_by">
                    {{ history.user.full_name }}
                  </span>
                  <span v-else> {{ history.assigned_by.full_name }} </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="!history.assigned_by">
                    Email: {{ history.user.email }}
                  </span>
                  <span v-else> Email: {{ history.assigned_by.email }} </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="!history.assigned_by"> Role: User </span>
                  <span v-else> Role: Admin </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </td>
        </tr>
      </tbody>

      <table-skeleton-loader v-if="loading" column="4" row />
    </v-simple-table>

    <no-list
      v-if="!hasHistories && !loading"
      class="my-14"
      details="No Available Meal Guide History"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'MealGuideHistoryList',

  directives: {
    infiniteScroll,
  },

  components: {
    NoList,
    TableSkeletonLoader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    lists: {
      type: Array,
      required: true,
    },

    meta: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      histories: this.lists,
    }
  },

  computed: {
    hasHistories() {
      return !!this.lists.length
    },

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },
  },

  watch: {
    lists(values) {
      this.histories = values
    },
  },
}
</script>
